.Resource {
    width: 75%;
    margin: 16px auto;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    padding: 15px;
    text-align: center;
}

.name{
    color: #000;
}

.phone {
    color: #354D8C;
}

.website {
    color: #354D8C;
}

.table {
	display: table;
    margin: 0 auto;
}

ul .icons {
	min-width: 696px;
	list-style: none;
	padding-top: 20px;
}

ul .icons {
    display: inline;
}