.search {
   width: 45%;
   padding: 12px 20px;
   border: 1px solid #000;
   border-radius: 6px;
   line-height: 20px;
   margin: 20px;
   font-size: large;
}

.select {
   margin: auto;
   width: 75%;
   padding: 12px 20px;
   line-height: 20px;
}