.info {
    margin: 15px;
    border: 2px solid black;
    color: black;
    background: transparent;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
}

.description {
    color: #000;
}

.icons {
    padding: 10px;
    text-align: center;
}